/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
// Layout
import Layout from 'layout'
import { Container, Hero, Columns, Section } from 'react-bulma-components'
import SiteNav from 'components/navbar'

// Meta Component
import Meta from 'components/Meta/Meta'

function PrivacyPolicy({ data }) {
  return (
    <Layout>
      <Hero className="pt-3">
        <Hero.Header>
          <SiteNav fullLogo />
        </Hero.Header>
        <Hero.Body>
          <Container>
            <h1 className="title">{data.page.title}</h1>
          </Container>
        </Hero.Body>
      </Hero>
      <Section>
        <Container className="content">
          <p
            className="has-columns-3"
            dangerouslySetInnerHTML={{ __html: data.page.content }}
          />
        </Container>
      </Section>
    </Layout>
  )
}
export default PrivacyPolicy

// Gatsby SEO Head
export function Head({ data }) {
  return (
    <Meta tags={data.page.seoMetaTags.tags}>
      <script type="application/ld+json" className="yoast-schema-graph">
        {`
        "@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://pocketworks.co.uk/#website","url":"https://pocketworks.co.uk/","name":"Pocketworks","description":"UK Mobile App Development Company","potentialAction":[{"@type":"SearchAction","target":"https://pocketworks.co.uk/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"WebPage","@id":"https://pocketworks.co.uk/#webpage","url":"https://pocketworks.co.uk/","name":"Pocketworks | App Developer | App Companies UK","isPartOf":{"@id":"https://pocketworks.co.uk/#website"},"datePublished":"2018-01-01T07:00:06+00:00","dateModified":"2021-01-01T07:00:06+00:00","description":"Pocketworks - UK app developers - one of the UK\u2019s leading mobile app development companies. Offering research-led design and a specialty in UX, we deliver over £100m in revenue for our clients. Our cross-functional team of designers and developers provides expertise in user research, design, iOS apps, Android apps, web apps and Flutter apps.","breadcrumb":{"@id":"https://pocketworks.co.uk/#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://pocketworks.co.uk/"]}]},{"@type":"BreadcrumbList","@id":"https://pocketworks.co.uk/#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"item":{"@type":"WebPage","@id":"https://pocketworks.co.uk/","url":"https://pocketworks.co.uk/","name":"Home"}}]}]
      `}
      </script>
    </Meta>
  )
}

export const query = graphql`
  query PrivacyQuery {
    page: datoCmsPage(slug: { eq: "privacy-policy" }) {
      id
      title
      slug
      excerpt
      content
      featuredMedia {
        gatsbyImageData(
          placeholder: TRACED_SVG
          imgixParams: { w: "800", fm: "jpg", auto: "compress" }
        )
        alt
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
